import ApiService from "@/core/services/api.service";

// action types
export const POST = "request-post";
export const GET = "request-get";
export const PATCH = "request-patch";
export const PUT = "request-put";
export const QUERY = "request-query";
export const DELETE = "request-delete";
export const UPLOAD = "upload";
export const SET_ERROR = "setError";
export const SET_MESSAGE = "setMessage";
export const SET_DATA = "setData";

// mutation types
const state = {};

const getters = {};


const commitErrors = (response) => {
	let requestErrors = [];
	if (response && response.status === 422) {
		for (let error in response.data) {
			if (response.data[error]) {
				for (let i = response.data[error].length - 1; i >= 0; i--) {
					requestErrors.push(response.data[error][i]);
				}
			}
		}
	} else if (response && response.status === 401) {
		requestErrors.push(response.data.error);
	} else if (response && response.status === 500) {
		requestErrors.push(response.data.message);
	} else if (response && response.status === 400) {
		requestErrors.push(response.data.message);
	} else if (response && response.status === 404) {
		requestErrors.push(response.data.message);
	} else if (response && response.status === 405) {
		requestErrors.push(response.data.message);
	} else {
		requestErrors.push(response.toString());
	}
	return requestErrors;
};
const actions = {
	[UPLOAD](context, request) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.upload(request.url, request.data)
				.then(({ data }) => {
					if (data.message) {
						context.commit(SET_MESSAGE, data.message);
					}
					context.commit(SET_DATA, data);
					resolve(data);
				})
				.catch((error) => {
					if (error && error.response) {
						context.commit(SET_ERROR, commitErrors(error.response));
						reject(error.response);
					} else {
						context.commit(SET_ERROR, commitErrors(error));
						reject(error);
					}
				});
		});
	},
	[POST](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.post(url, data)
				.then(({ data, message }) => {
					resolve({ data, message });
				})
				.catch((response) => {
					reject(response);
				});
		});
	},
	[GET](context, { url }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.get(url)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[PATCH](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(url, data)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[PUT](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.put(url, data)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[QUERY](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.query(url, data)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[DELETE](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.delete(url, data)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
};

const mutations = {};

export default {
	state,
	actions,
	mutations,
	getters,
};
